import { API_HOST } from '@/utils/constants';
import { message } from 'antd';
import { history } from 'umi';
import axios from 'axios';
import type {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import { clearAllCookie } from './index';

// 定义 HTTP 响应状态码对应的消息
const codeMessage: Record<number, string> = {
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作',
  401: '用户没有权限（令牌、用户名、密码错误）',
  403: '用户得到授权，但是访问是被禁止的',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  406: '请求的格式不可得',
  410: '请求的资源被永久删除，且不会再得到的',
  422: '当创建一个对象时，发生一个验证错误',
  500: '服务器发生错误，请检查服务器',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
};

// 创建带有基本配置的 axios 实例
const instance: AxiosInstance = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
});

// 创建用于下载文件的 axios 实例
export const downloadRequest: AxiosInstance = axios.create({
  baseURL: API_HOST,
  withCredentials: false,
  responseType: 'blob',
});

// 请求拦截器
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token') || '';

  const headers: AxiosRequestHeaders | any = {
    Authorization: `Bearer ${token}`,
    'X-CSRF-Token': token,
    'Access-Control-Allow-Origin': '*',
  };
  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
  };
});

// 响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response && response.status === 200) {
      if (response.data?.code === 0) {
        return response.data?.data;
      }

      // 下载文件
      const disposition = response.headers['content-disposition'];
      if (disposition && disposition.includes('attachment')) {
        return response.data;
      }
    }

    const msg = response?.data?.message;
    if (!!msg && typeof msg === 'string') message.error(msg);
    return Promise.reject(response.data);
  },
  (error) => {
    if (error?.response?.status === 401) {
      // token 过期
      clearAllCookie();
      history.replace('/users/sign_in');
      return Promise.reject(error.response.data);
    }

    const msg = error?.response?.data?.message;
    message.error(
      msg ||
      codeMessage[error?.response?.status] ||
      error?.response?.statusText ||
      '请求失败',
    );
    return Promise.reject(error.response?.data);
  },
);

export default instance;
